import './App.css';
import { useState, useMemo, useCallback, useRef } from 'react';

import photos from './photos';
import tags from './tags';
import Filters from './Filters';
import Masonry from './Masonry';
import Gallery from './Gallery';

function getInitialPeopleState() {
  if (window.location.hash) {
    const hash = window.location.hash.substring(1);
    const parts = hash.split('|');
    return parts.filter(part => tags.find(tag => tag.id === part));
  }

  return [];
}

function App() {
  const [selectedPeople, setSelectedPeople] = useState(getInitialPeopleState());
  const [selectedMoments, setSelectedMoments] = useState([]);
  const galleryRef = useRef(null);

  const selectedPhotos = useMemo(() => {
    if (!selectedPeople.length && !selectedMoments.length) {
      return photos;
    }

    return photos.filter(photo =>
      (!selectedPeople.length || selectedPeople.find(tagId => photo.tags[tagId] === true))
      && (!selectedMoments.length || selectedMoments.find(tagId => photo.tags[tagId] === true))
    );
  }, [selectedPeople, selectedMoments]);

  const onPhotoClicked = useCallback((photo) => {
    galleryRef.current && galleryRef.current.open(photo);
  }, [galleryRef]);

  return (
    <div className="App">
      <Header />
      <Gallery
        ref={galleryRef}
        photos={selectedPhotos}
      />
      <Filters
        selectedPeople={selectedPeople}
        selectedMoments={selectedMoments}
        onSelectedPeopleChange={setSelectedPeople}
        onSelectedMomentsChange={setSelectedMoments}
      />
      <Masonry photos={selectedPhotos} onPhotoClicked={onPhotoClicked} />
    </div>
  );
}

function Header() {
  return (
    <div className="Header">
      Christie & Rodrigo ・ Fotos 📸
    </div>
  )
}

export default App;
