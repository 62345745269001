import { useState, useEffect, useLayoutEffect, useMemo } from 'react';

function Masonry(props) {
  const { photos, spacing = 8, onPhotoClicked } = props;

  const [columnCount, setColumnCount] = useState(_columnCount());
  const [columnWidth, setColumnWidth] = useState(_columnWidth());
  const [shownPhotos, setShownPhotos] = useState(Math.min(50, photos.length));

  useEffect(() => {
    const handleResize = () => {
      setColumnCount(_columnCount());
      setColumnWidth(_columnWidth());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  useLayoutEffect(() => {
    setShownPhotos(50);
  }, [photos.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShownPhotos(Math.min(shownPhotos + 50, photos.length));
    }, 250);

    return () => clearInterval(interval);
  });

  const masonryColumns = useMemo(() => {
    const columns = [];
    const columnHeights = [];

    for (let i = 0; i < columnCount; i++) {
      columns.push([]);
      columnHeights.push(0);
    }

    for (let i = 0; i < photos.length; i++) {
      const elHeight = Math.round(photos[i].thumbSize[1] * columnWidth / photos[i].thumbSize[0]);
      const minColumnIndex = columnHeights.indexOf(Math.min(...columnHeights));
      columns[minColumnIndex].push({ ...photos[i], idx: i });
      columnHeights[minColumnIndex] += elHeight + spacing;
    }

    return columns;
  }, [photos, columnCount, columnWidth]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: spacing, margin: '0 auto', justifyContent: 'center' }}>
      {masonryColumns.map(column => (
        <div style={{ flexGrow: '0', flexBasis: columnWidth }}>
          {column.map((photo) => (
            photo.idx < shownPhotos ? <img
              key={photo.id}
              style={{ marginBottom: spacing }}
              className="gallery-image"
              // Here, we're using the ref to dynamically open the gallery to the exact image that was clicked by the user
              onClick={() => onPhotoClicked && onPhotoClicked(photo)}
              src={`/gallery/${photo.id}_thumb.jpg`}
              width={columnWidth}
              height={Math.round(photo.thumbSize[1] * columnWidth / photo.thumbSize[0])}
            /> : (
              <div className="gallery-image" style={{
                width: columnWidth,
                height: Math.round(photo.thumbSize[1] * columnWidth / photo.thumbSize[0]),
                marginBottom: spacing,
              }} onClick={() => onPhotoClicked && onPhotoClicked(photo)} />
            )
          ))}
        </div>
      ))}
    </div>
  );
}

const _columnCount = () => {
  const ceil = Math.ceil((window.innerWidth - 40) / 278);
  const floor = Math.floor((window.innerWidth - 40) / 278);

  if (ceil <= 3) return Math.max(2, ceil);
  return floor;
}

const _columnWidth = () => {
  const availableWidth = window.innerWidth - 40 ;
  return Math.min(availableWidth / _columnCount(), 270);
}

export default Masonry;
