const tags = [
  { id: 'lu6h-gS9', name: 'Akira Taniguchi', isPerson: true },
  { id: '96OAM2Wl', name: 'Alessandra Longhi Simões de Almeida Seri', isPerson: true },
  { id: 'Y4TishhL', name: 'Alice Santos Biagioni Rieper', isPerson: true },
  { id: 'XI5HqaZZ', name: 'Ana Cláudia Marcelino', isPerson: true },
  { id: 'ocyuJbj7', name: 'Ana Clementina Vieira de Almeida', isPerson: true },
  { id: 'J2tsRpLn', name: 'Ana Lúcia Seghessi', isPerson: true },
  { id: 'Cg7WeB_h', name: 'André Battagello', isPerson: true },
  { id: 'HEL5wcov', name: 'Andrea Longhi Simões Almeida', isPerson: true },
  { id: 'e2TQkGDb', name: 'Armando Simões de Almeida', isPerson: true },
  { id: 'OIa8nJZV', name: 'Arthur Keitaro Akagi', isPerson: true },
  { id: 'TWxNa1ts', name: 'Bárbara Sototuka Schujmann', isPerson: true },
  { id: '52YimPki', name: 'Beatriz Kuabara', isPerson: true },
  { id: 'pGRGBY8h', name: 'Belinha 🐶 (Inês e Milton)', isPerson: true },
  { id: '5PdhSoh6', name: 'Bety Doi', isPerson: true },
  { id: '45KKO8S-', name: 'Bruno Bertolotti Falleiros', isPerson: true },
  { id: 'grfBRktX', name: 'Bruno Matsumoto', isPerson: true },
  { id: 'mftcnN6y', name: 'Bruno Seri', isPerson: true },
  { id: 'w2mZZxes', name: 'Caetano (Dani e Wolney)', isPerson: true },
  { id: 'eDRzmiTQ', name: 'Camila Akemi Karino', isPerson: true },
  { id: 'H6SiIRtO', name: 'Carla Teodoro', isPerson: true },
  { id: 'DWAdwRUh', name: 'Carmem Lúcia Pereira da Silva', isPerson: true },
  { id: 'YgVt98_U', name: 'Carol Brant', isPerson: true },
  { id: 'wUxyKEVt', name: 'Carol Hernandez', isPerson: true },
  { id: 'qaAZgBCm', name: 'Carolina Takizawa', isPerson: true },
  { id: 'zmFkkWjE', name: 'Cesar Ryudi Kawakami', isPerson: true },
  { id: 'Ri6hG_1m', name: 'Christie Sototuka', isPerson: true },
  { id: 'K5_z78Sp', name: 'Claudia Biasetto', isPerson: true },
  { id: 'DHSsNNZl', name: 'Cristina Mary Kuabara Sototuka', isPerson: true },
  { id: '32k4DsSb', name: 'Daniel Costa Bucher', isPerson: true },
  { id: '50T1fanl', name: 'Daniel Simões de Almeida Junior', isPerson: true },
  { id: 'zuISU796', name: 'Daniel Simões de Almeida Neto', isPerson: true },
  { id: 'omdMzXyG', name: 'Daniel Simões de Almeida', isPerson: true },
  { id: '71qeIftn', name: 'Daniela Corumba', isPerson: true },
  { id: 'UvRLbIzH', name: 'Débora Simões de Almeida', isPerson: true },
  { id: 'loxY2I2Y', name: 'Denise A A Oliveira', isPerson: true },
  { id: 'Wqj-2XQd', name: 'Edgard Katsuhico Ito', isPerson: true },
  { id: 'E2iH_FpC', name: 'Eduardo Colagrossi Paes Barbosa', isPerson: true },
  { id: 'E6AUy53p', name: 'Eduardo Colombari', isPerson: true },
  { id: 'F8HK49Vd', name: 'Eiko K S Hojo', isPerson: true },
  { id: 'PkQ5dxJu', name: 'Ênio Santos', isPerson: true },
  { id: 'KZiEKcSB', name: 'Eric Taniguchi', isPerson: true },
  { id: 'CCa1wEc4', name: 'Érica Simões de Almeida', isPerson: true },
  { id: 'qj_sFtV7', name: 'Evandro Ferreira Yorioka', isPerson: true },
  { id: 'BgGhjrXU', name: 'Fábio Eigi Imada', isPerson: true },
  { id: 'vqGrnskN', name: 'Fábio Jitsuaki Fugiyama', isPerson: true },
  { id: 'IE_XF6QL', name: 'Fábio Kuabara', isPerson: true },
  { id: 'yr54mzdR', name: 'Fabricio Andreotti', isPerson: true },
  { id: 'tdX2ezCA', name: 'Fátima Fabrin', isPerson: true },
  { id: 'hLiZOg7k', name: 'Felipe Ivonez Alexandre', isPerson: true },
  { id: 'BOb27BzB', name: 'Felipe Menhem', isPerson: true },
  { id: 'TT1n4dwJ', name: 'Felipe Sototuka', isPerson: true },
  { id: 'hZo3cgwn', name: 'Fernanda Simões de Almeida', isPerson: true },
  { id: 'd9wlk37g', name: 'Luis Fernando Eiiti Arashiro', isPerson: true },
  { id: 'fh6RFW98', name: 'Florent Prevost', isPerson: true },
  { id: 'LkF89CMe', name: 'Gabriel (Dani e Wolney)', isPerson: true },
  { id: 'em5QHivw', name: 'Gabriel Kendjy Koike', isPerson: true },
  { id: '2aBPvuJU', name: 'Gabriel Longhi', isPerson: true },
  { id: 'oNSZx1EF', name: 'Gabriela Christine Santos', isPerson: true },
  { id: 'yu00G_-y', name: 'George Hideyuki Hirata', isPerson: true },
  { id: '2pPOQVL3', name: 'Giovanna Marcelino', isPerson: true },
  { id: '_8DaHtCN', name: 'Henrique Hojo', isPerson: true },
  { id: 'apXl3lI8', name: 'Henrique Simões de Almeida', isPerson: true },
  { id: 'G9xqVpTK', name: 'Iara (Ana e Oliver)', isPerson: true },
  { id: '5Vl0RifM', name: 'Ines Seiko Kuabara', isPerson: true },
  { id: '91ya8vlS', name: 'Iris Fabrin Sototuka', isPerson: true },
  { id: 'Sy-2NzHk', name: 'Isabel Kuabara', isPerson: true },
  { id: 'in2p04_O', name: 'Joao Leonardo Azevedo Casemiro', isPerson: true },
  { id: 'jJ2AbG0u', name: 'Jorge Kuma Sototuka', isPerson: true },
  { id: 'tE6LcHR6', name: 'Julia Bueno', isPerson: true },
  { id: '4k4px-in', name: 'Julia Monteiro', isPerson: true },
  { id: '14I7sNGe', name: 'Juliana Rossi', isPerson: true },
  { id: 'YAfVGDas', name: 'Jun Hojo Migliano', isPerson: true },
  { id: '5UtAJj4C', name: 'Jun Tomida', isPerson: true },
  { id: 'o3sqY30l', name: 'Karina Matsushita', isPerson: true },
  { id: 'lHDj3I-2', name: 'Kenzo Matsushita', isPerson: true },
  { id: 'LjgXKT_C', name: 'Laís Fabrin Sototuka', isPerson: true },
  { id: 'ES3sy3A3', name: 'Larissa Hojo', isPerson: true },
  { id: 'F4h_B5RP', name: 'Larissa Yumi Cavazzana', isPerson: true },
  { id: '2RgFlHRP', name: 'Leandro Carabet', isPerson: true },
  { id: 'C_U5FJql', name: 'Leonardo Hori', isPerson: true },
  { id: 'D3Wd0eps', name: 'Leonardo Kuabara', isPerson: true },
  { id: '3ULxcKYH', name: 'Letícia Makrakis', isPerson: true },
  { id: '_Fsi6s1C', name: 'Liana Wakano', isPerson: true },
  { id: '8b2rTmm5', name: 'Lidia Sadaco Minamizaki Ikuta', isPerson: true },
  { id: 'V9yRja9h', name: 'Livia Diniz', isPerson: true },
  { id: 'c2oonA_C', name: 'Lucia Suemi Taniguchi', isPerson: true },
  { id: 'GtelfDr7', name: 'Lygia Aguiar Del Matto', isPerson: true },
  { id: '8NZQEnnw', name: 'Marcelo Kuabara', isPerson: true },
  { id: 'kxyC9lnS', name: 'Marcelo Pesse', isPerson: true },
  { id: 'mVvwU8Rp', name: 'Marcelo Yamada', isPerson: true },
  { id: 'xeNTkSHE', name: 'Marcos Seri', isPerson: true },
  { id: 'cR4kfLr7', name: 'Thiago Ikuta', isPerson: true },
  { id: 'rfGDj_Ka', name: 'Maria Arizono Akagi', isPerson: true },
  { id: 'EQ7_fUCj', name: 'Mauricio Keitaro Akagi', isPerson: true },
  { id: 'KhyJWnLY', name: 'Isis Harumi Akagi', isPerson: true },
  { id: 'viyguCWB', name: 'Célia Akemi Takahashi', isPerson: true },
  { id: 'FloXYWNN', name: 'Maria Eliza Longhi Almeida', isPerson: true },
  { id: '13eMdXDD', name: 'Maria Júlia Simões de Almeida', isPerson: true },
  { id: 'xDSRUvc5', name: 'Marília Romano', isPerson: true },
  { id: 'owGQlPxs', name: 'Marina Coelho da Costa Simões de Almeida', isPerson: true },
  { id: 'r9kJDHsP', name: 'Marina Diedrich', isPerson: true },
  { id: 'AIxKiVOF', name: 'Marina Harumi', isPerson: true },
  { id: 'OUZetwHn', name: 'Mayara Rodrigues', isPerson: true },
  { id: 'IpHE2MXa', name: 'Massahiko Sototuka', isPerson: true },
  { id: 'hL8kt85g', name: 'Massuo Kuabara', isPerson: true },
  { id: '49DNFzoz', name: 'Mauricio Migliano', isPerson: true },
  { id: 'sTSGC0c-', name: 'Mauro Romano', isPerson: true },
  { id: 'B69hPuoF', name: 'Mika Tamaki Hirata', isPerson: true },
  { id: 'wdtnh5ML', name: 'Milene (Gabriel e Milene)', isPerson: true },
  { id: 'IYuYKKzR', name: 'Milton', isPerson: true },
  { id: 'A0nhuC8O', name: 'Murilo', isPerson: true },
  { id: 'j1yFNWY5', name: 'Nadine Wahbeh', isPerson: true },
  { id: 'BqYEHCv6', name: 'Natalie Mie Takahashia', isPerson: true },
  { id: 'pB0RdkMw', name: 'Nathalia Miwa Arasaki Menezes Freitas', isPerson: true },
  { id: '0X490INz', name: 'Nathalia Paro', isPerson: true },
  { id: '3rl6dZ7n', name: 'Nicolas (Bruno e Pam)', isPerson: true },
  { id: 'rK4nOlpc', name: 'Oliver Schujmann', isPerson: true },
  { id: '_vcYXdbm', name: 'Orlando Simões de Almeida', isPerson: true },
  { id: 'VfGt2biw', name: 'Ornella Gonçalves Zumpano', isPerson: true },
  { id: 'kcD3EAve', name: 'Pamela Mayumi Shimizu', isPerson: true },
  { id: 'UAcR1BJG', name: 'Rafael Oda', isPerson: true },
  { id: 'cZePPZKo', name: 'Rafael Yuji', isPerson: true },
  { id: 'TjH-kKsu', name: 'Raphael Biagioni Rieper', isPerson: true },
  { id: 'zgTlIdsl', name: 'Raquel Monteiro Silva', isPerson: true },
  { id: 'cIplwAcl', name: 'Rebeca Yoshisato', isPerson: true },
  { id: '_tj11nRB', name: 'Regina Sototuka', isPerson: true },
  { id: 'GHpJWK9o', name: 'Reinaldo Emilio Pak', isPerson: true },
  { id: '7ALS2Vwn', name: 'Renato Silva', isPerson: true },
  { id: 'HmJ--nlK', name: 'Rodrigo Simões de Almeida', isPerson: true },
  { id: 'IF8_k8Ob', name: 'Rubem Hojo', isPerson: true },
  { id: 'DrRmZa3f', name: 'Rui Sototuka', isPerson: true },
  { id: '8B4NeZKj', name: 'Samantha Longhi Simoes de Almeida', isPerson: true },
  { id: 'KFMV01R9', name: 'Samara Simões de Almeida', isPerson: true },
  { id: '2K5XdgZy', name: 'Samuel (Carol e Felipe)', isPerson: true },
  { id: 'O9dUsRj-', name: 'Sarang Eventos', isPerson: true },
  { id: '78v-g2je', name: 'Thiago Simões Colombari', isPerson: true },
  { id: 'uPU8mN5w', name: 'Tiago Armão', isPerson: true },
  { id: 'A6jHbfGP', name: 'Tiago Nunes Michel', isPerson: true },
  { id: '11XrR10B', name: 'Valter Hideo Onishi', isPerson: true },
  { id: 'KCu7-2na', name: 'Vanessa Mayumi Nakamura Onishi', isPerson: true },
  { id: 'Q0HfqnJZ', name: 'Vera Lúcia Silva', isPerson: true },
  { id: 'wfhrXRGH', name: 'Victor Rios', isPerson: true },
  { id: '6i8Wcx_C', name: 'Vitor Tatsuo Itocazu', isPerson: true },
  { id: '4NExLgZ5', name: 'Wolney Mello', isPerson: true },
  { id: 'ClGDdstk', name: 'Yuka Taniguchi', isPerson: true },
  { id: '2d4ZCMMM', name: 'Yumi Cristina Komatsu', isPerson: true },
  { id: 'fgVda2X-', name: 'Yuzo Hojo Migliano', isPerson: true },
];

export default tags;
