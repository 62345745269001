import Select from 'react-select';

import tags from './tags';
import { useMemo } from 'react';

function Filters(props) {
  const { selectedPeople, selectedMoments, onSelectedMomentsChange, onSelectedPeopleChange } = props;

  const peopleOptions = useMemo(() => {
    return tags.map(tag => ({ label: tag.name, value: tag.id })).sort((a, b) => normalize(a.label).localeCompare(normalize(b.label)));
  }, [tags]);

  const momentOptions = useMemo(() => [
    {
      value: 'zsteLjF0',
      label: 'Decoração e Ambientes',
    },
    {
      value: 'EGf03pa9',
      label: 'Preparação Christie',
    },
    {
      value: 'rw_IYXfd',
      label: 'Chegada dos Convidados'
    },
    {
      value: 'HJDHrpGH',
      label: 'Cerimônia',
    },
    {
      value: 'pS5pgJXs',
      label: 'Kahoot',
      indent: true,
    },
    {
      value: 'ceedCOTR',
      label: 'Book do Casal',
    },
    {
      value: 'WV2rpt27',
      label: 'Festa',
    },
    {
      value: 'elFfljbp',
      label: 'Dança Circular',
      indent: true
    },
    {
      value: 'FNIN2A3C',
      label: 'Bouquet de Fitas',
      indent: true,
    }
  ], []);

  const selectedPeopleOptions = useMemo(() => (
    peopleOptions.filter(option => selectedPeople.includes(option.value))
  ), [peopleOptions, selectedPeople]);

  const selectedMomentOptions = useMemo(() => (
    momentOptions.filter(option => selectedMoments.includes(option.value))
  ), [momentOptions, selectedMoments]);

  return (
    <div className="Filters">
      <Select
        className="Filter"
        classNamePrefix="react-select"
        isMulti
        placeholder="Filtrar por convidados..."
        options={peopleOptions}
        value={selectedPeopleOptions}
        onChange={value => onSelectedPeopleChange(value.map(option => option.value))}
      />
      <Select
        className="Filter"
        classNamePrefix="react-select"
        isMulti
        placeholder="Filtrar por momentos..."
        formatOptionLabel={(option, meta) => option.indent && meta.context === 'menu' ? <span style={{ paddingLeft: '1em', color: '#888' }}>{option.label}</span> : option.label}
        options={momentOptions}
        value={selectedMomentOptions}
        onChange={value => onSelectedMomentsChange(value.map(option => option.value))}
      />
    </div>
  );
}

function normalize(text) {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
}

export default Filters;
