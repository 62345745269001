import { useRef, useMemo, forwardRef, useImperativeHandle } from 'react';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumb from 'lightgallery/plugins/thumbnail';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

const PLUGINS = [lgZoom, lgThumb];

function Gallery(props, ref) {
  const lightbox = useRef(null);

  const dynamicEl = useMemo(() => props.photos.map(photo => ({
    src: `/gallery/${photo.id}.jpg`,
    thumb: `/gallery/${photo.id}_thumb.jpg`,
    width: `${photo.size[0]}px`,
  })), [props.photos]);

  useImperativeHandle(ref, () => {
    return {
      open: (photo) => {
        const index = props.photos.findIndex(p => p.id === photo.id);
        if (index === -1) return;
        lightbox.current && lightbox.current.openGallery(index);
      }
    };
  });

  return useMemo(() =>
    <LightGallery
      // Once the component initializes, we'll assign the instance to our React ref.  This is used in the onClick() handler of each image in the Masonry layout
      onInit={(ref) => {
        if (ref) {
          lightbox.current = ref.instance;
        }
      }}
      mobileSettings={{ controls: false, showCloseIcon: true, download: true }}
      plugins={PLUGINS}
      // These options turn the component into a "controlled" component that let's us determine when to open/close it
      dynamic
      dynamicEl={dynamicEl}
    />,
  [dynamicEl])
}

export default forwardRef(Gallery);
